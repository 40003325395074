import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import { appendScript } from "../functions/appendScript"

const newsletter = ({ data, location }) => {

    appendScript(`(function(w,d,e,u,f,l,n){w[f]=w[f]||function(){(w[f].q=w[f].q||[])
             .push(arguments);},l=d.createElement(e),l.async=1,l.src=u,
             n=d.getElementsByTagName(e)[0],n.parentNode.insertBefore(l,n);})
             (window,document,'script','https://assets.mailerlite.com/js/universal.js','ml');
             ml('account', '103552');`)
  return (
    <Layout location={location} >
      <div class="ml-embedded" data-form="ruaJrC"></div>
    </Layout>
  )
}

export default newsletter

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`