import ReactDOM from 'react-dom';



export const appendScript = (innerHtml) => {
    if (typeof window !== 'undefined') {
        const script = document.createElement("script");
        script.innerHTML = innerHtml;
    
        document.body.appendChild(script);
    }

}